import React from 'react'
import Footer from "../components/footer"
// import styles from "../components/layout-teams.module.scss"
import MobileNav from "../components/mobile-nav"

const Layout = (props) => (
  <div>
      <div>
        {props.children}
        <Footer />
        <MobileNav />
      </div>
  </div>
)

export default Layout