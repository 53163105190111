import React from "react"
import Hero from "../../components/hero"
import SiteMetaData from "../../components/sitemeta"
import teamStyles from "../../components/teams.module.scss"
import TeamData from "../../components/team-data.js"
import Layout from "../../components/layout-teams"

const LadiesTeam = ({data}) => (
  <Layout data={data} media="images">
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title="RF Ladies' Team | RF Dance"
        description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
        pathname="teams/ladies"/>
      <Hero 
        titleRed="RF"
        titleWhite="Ladies"/>

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          <TeamData
              name="RF Ladies Team Auditions"
              subheading="All levels"
              link=" https://www.eventbrite.com/e/92296159297"
              date="March 31, 2020">
              <p>The RF Ladies Team is a welcoming, all-levels team that focuses on ladies styling and footwork.</p>
            </TeamData>

        </div>
      </div>
    </div>
  </Layout>
)

export default LadiesTeam