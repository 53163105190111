/** Team data - rows for team auditions and events
 * 
 * Props:
 * name (required), subheading, ctaHeader, link, makeup, date,
 * 
 **/

import React from "react"
import teamStyles from "../components/teams.module.scss"
import "../styles/layout.scss"

const TeamData = props => (
  <div className={teamStyles.team}>
    <div className="columns">
      <div className={`${teamStyles.details} display--inline-block column is-three-quarters`}>
        <h4>{props.name} <span>{props.subheading}</span></h4>
        {props.children}
      </div>
      <div className={`${teamStyles.cta} display--inline-block column is-one-quarter`}>
        {(props.ctaHeader ? <p>{props.ctaHeader}</p> : '')}
        <p>
          <span style={({'display':`block`})}>{props.date}</span>
          {(props.makeup ? <span style={({'display':`block`,'fontSize':`0.9em`})}><em><a target="_blank" rel="noopener noreferrer" href="https://www.eventbrite.com/e/rf-make-up-audition-registration-59088611628" title="Make up audition sign up">Make up auditions</a> are March 31.</em></span> : '' )}

          {(props.comment ? <span style={({'display':`block`,'fontSize':`0.9em`})}><em>{props.comment}</em></span> : '')}
        </p>
        {(props.link ? <a className="button is-info is-rounded" href={props.link}>Sign up</a> : '')}
        {(props.link2 ? <div style={({'marginTop':`2em`})}><p>{props.date2}</p><a className="button is-info is-rounded" href={props.link2}>Sign up</a></div> : '')}
      </div>
    </div>
  </div>
)

export default TeamData